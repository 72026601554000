<template>
    <div class="mb-3 card">

        <div>
            <v-tabs v-model="tab">
                <v-tab key="list">
                    {{ $t('erp.lang_nav_taxrates') }}
                </v-tab>
                <v-tab key="add">{{ $t('erp.lang_tax_create') }}</v-tab>
                <v-tab key="offsite">{{ $t('erp.lang_offsiteSaleTax') }}</v-tab>

                <v-tabs-items v-model="tab">
                    <v-tab-item key="list">
                        <!-- Table -->
                        <Datatable v-show="showTable" ref="taxSetupDatatable"
                                   :api-endpoint="ENDPOINTS.DATATABLES.ERP.TAXSETUP"
                                   :datatable-headers="datatableHeaders"
                                   :excel-columns="excelColumns"
                                   excel-file-name="TaxSetup"
                                   @editEntry="entryEdit"
                                   @deleteEntry="entryDelete"
                                   @deleteEntries="entriesDelete"
                                   show-delete-buttons
                                   show-edit-buttons
                                   :permissionDelete="this.$store.getters['permissions/checkPermission']('accounting_deleteTaxRates')"
                                   :permissionEdit="this.$store.getters['permissions/checkPermission']('accounting_editTaxRates')"
                        />
                        <v-container v-if="showUpdate" fluid class="pt-8">
                            <v-layout row>

                                <!-- Table: Click -->
                                <v-flex xs12 md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="text"
                                                  :label="$t('erp.lang_tax_name')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 md3 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="taxValue"
                                                  :label="$t('erp.lang_tax_value')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 md2 mr-2 ml-2>
                                    <v-checkbox
                                            v-model="taxStandard"
                                            :label="$t('generic.lang_standard')"
                                            autocomplete="off"
                                            required
                                    ></v-checkbox>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="error" @click="deleteData">{{ $t('generic.lang_delete') }}</v-btn>
                                    <v-btn color="success" :disabled="text.length < 1" @click="updateData">{{
                                        $t('generic.lang_edit') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="add">
                        <v-container fluid class="pt-8">
                            <v-layout row>
                                <v-flex xs12 md6 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="taxSetupNameAdd"
                                                  :label="$t('erp.lang_tax_name')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 md3 mr-2 ml-2>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="taxSetupValueAdd"
                                                  :label="$t('erp.lang_tax_value')"
                                                  autocomplete="off"
                                                  type="number"
                                                  step="0.01"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 md2 mr-2 ml-2>
                                    <v-checkbox
                                            v-model="taxSetupStandardAdd"
                                            :label="$t('generic.lang_standard')"
                                            autocomplete="off"
                                            required
                                    ></v-checkbox>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="success" :disabled="taxSetupNameAdd.length < 1" @click="addData">{{
                                        $t('generic.lang_add') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="offsite">
                        <v-container fluid>
                            {{ $t('settings.lang_warecreate_taxsettingouterhouse') }}:<br><br>
                            <v-layout>
                                <v-flex md12>
                                    <v-text-field outlined
                                                  v-model="offsiteSalesTax"
                                                  :label="$t('erp.lang_offsiteSaleTax')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="success" :disabled="!offsiteSalesTax || offsiteSalesTax == null"
                                           @click="updateOffsiteSalesTax">{{ $t('generic.lang_apply') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<style>
    .dataTables_filter {
        display: none;
    }
</style>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import Datatable from "../../datatable/Datatable";
    import mixin from "../../../mixins/KeyboardMixIns";


    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                text: "",
                taxValue: 0,
                taxStandard: 0,
                taxSetupNameAdd: "",
                taxSetupValueAdd: 0,
                taxSetupStandardAdd: 0,
                search: "",
                loading: false,
                showTable: true,
                showCreate: false,
                showUpdate: false,
                offsiteSalesTax: null,
                tab: 0,
                // --- Datatable ---
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide: true
                    },
                    {text: this.$t('erp.lang_tax_name'), value: "taxName"},
                    {text: this.$t('erp.lang_tax_value'), value: "taxVal",sort: "asc"},
                    {text: this.$t('generic.lang_standard'), value: "taxStandard"}
                ],
                excelColumns: [

                    {
                        label: "ID",
                        field: 'id',
                    },
                    {
                        label: this.$t('erp.lang_tax_name'),
                        field: "taxName",
                    },
                    {
                        label: this.$t('erp.lang_tax_value'),
                        field: "taxVal",
                    },
                    {
                        label: this.$t('generic.lang_standard'),
                        field: "taxStandard",
                    }
                ],
            }
        },

        watch: {
            tab: function () {
                if (this.tab === 1) {
                    this.showTable = true;
                    this.showUpdate = false;
                }
            }
        },

        methods: {
            addData: function () {
                let self = this;
                this.axios.post(ENDPOINTS.ERP.TAXSETUP.CREATE, {
                    tax_name: self.taxSetupNameAdd,
                    tax_value: self.taxSetupValueAdd,
                    taxStandard: self.taxSetupStandardAdd
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('erp.lang_taxSetup_added'),
                            color: "success"
                        });

                        self.taxSetupNameAdd = "";
                        self.taxSetupValueAdd = 0;
                        self.taxSetupStandardAdd = 0;
                        self.showList();
                        self.$refs.taxSetupDatatable.getDataFromApi();
                        self.$refs.taxSetupDatatable.resetSelectedRows();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            createNew: function () {
                this.resetData();
                this.showCreate = true;
                this.tab = 1;
                this.showTable = false;
            },
            updateData: function () {
                let self = this;
                this.axios.post(ENDPOINTS.ERP.TAXSETUP.UPDATE, {
                    taxEditID: this.id,
                    tax_name: this.text,
                    tax_value: this.taxValue,
                    taxStandard: this.taxStandard === true ? 1 : 0,
                }).then((res) => {
                    if (res.data.status === "SUCCESS") {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });
                        self.resetData();
                        self.showList();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            loadOffsiteTax: function () {
                let self = this;
                self.axios.get(ENDPOINTS.ERP.TAXSETUP.OFFSITE.GET)
                    .then((res) => {
                        if (res.status === 200) {
                            self.offsiteSalesTax = res.data.ausserhaus_tax;
                        } else {
                            Events.$emit("showSnackbar", {
                                message: self.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        }
                    }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: self.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            updateOffsiteSalesTax: function () {
                let self = this;
                self.axios.post(ENDPOINTS.ERP.TAXSETUP.OFFSITE.UPDATE, {
                    ausserhaus_tax_val: self.offsiteSalesTax
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });

                        self.resetData();
                        self.showList();
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            deleteData: function (idsToDelete = []) {
                let self = this;
                this.$swal({
                    title: this.$t('accounting.lang_deleteTaxRate'),
                    text: this.$t('accounting.lang_deleteTaxRateText'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.TAXSETUP.DELETE, {
                            editTaxID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('erp.lang_taxSetupDeleted'),
                                    color: "success"
                                });

                                self.resetData();
                                self.$refs.taxSetupDatatable.getDataFromApi();
                                self.$refs.taxSetupDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },
            entryEdit(entry) {
                let self = this;

                self.axios.post(ENDPOINTS.ERP.TAXSETUP.GET, {
                    editTaxID: entry.id
                }).then((res) => {
                    if (res.status === 200) {
                        self.id = entry.id;
                        self.text = res.data.formfillData.textFields.taxName;
                        self.taxValue = res.data.formfillData.textFields.taxValue;
                        self.taxStandard = res.data.formfillData.textFields.defaultTax === 1 ? true : false;
                        self.showTable = false;
                        self.showUpdate = true;

                        self.$refs.taxSetupDatatable.getDataFromApi();
                        self.$refs.taxSetupDatatable.resetSelectedRows();

                    } else {
                        Events.$emit("showSnackbar", {
                            message: self.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: self.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
            entryDelete(entry) {
                this.id = entry.id;
                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            },
            resetData: function () {
                this.showCreate = false;
                this.showUpdate = false;
                this.showTable = true;
                this.id = null;
                this.text = "";
            },
            showList: function () {
                this.resetData();
                this.tab = 0;
            },
        },
        mounted() {

            this.loadOffsiteTax();
        }
    }
</script>
<template>
    <div>
        <page-title :heading="$t('erp.lang_nav_taxrates') " :permissionAdd="this.$store.getters['permissions/checkPermission']('accounting_createTaxRates')" :subheading="$t('erp.lang_nav_taxrates')" :icon=icon></page-title>
        <div class="app-main__inner">
        <tax-setup></tax-setup>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import TaxSetup from "../../../components/erp/settings/TaxSetup";

    export default {
        components: {
            PageTitle,
            TaxSetup
        },

        data: () => ({
            icon: 'pe-7s-calculator icon-gradient bg-tempting-azure',
        })
    }
</script>